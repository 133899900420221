// Font Example
@font-face {
  font-family: "Kenteken";
  src: url("../../../fonts/kenteken/Kenteken.ttf") format("truetype");
}

@font-face {
  font-family: "icon";
  src: url("../../../fonts/icons/icons.ttf") format("truetype");
}

@font-face {
  font-family: "icomoon";
  src: url("../../../fonts/icons/icomoon.ttf") format("truetype");
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icomoon-"],
[class*=" icomoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icomoon-phone:before {
  content: "\e912";
}
.icon-check-circle:before {
  content: "\e904";
  color: #e67a01;
}
.icon-circle-button-down:before {
  content: "\e905";
  color: #1d2d58;
}
.icon-circle-button-right:before {
  content: "\e906";
}
.icon-circle-button-left {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.icon-circle-button-left:before {
  content: "\e906";
}
.icon-info:before {
  content: "\e907";
  color: #1d2d58;
}
.icon-arrow-left:before {
  content: "\e900";
  color: #1d2d58;
}
.icon-biycle:before {
  content: "\e901";
  color: #1d2d58;
}
.icon-bus:before {
  content: "\e902";
  color: #1d2d58;
}
.icon-car-front:before {
  content: "\e903";
  color: #1d2d58;
}
.icon-edit-pen:before {
  content: "\e90a";
  color: #192f59;
}
.icon-stars:before {
  content: "\e90c";
  color: #fc0;
}
