/*------------------------------------*\
  #STANDARD BUTTON
\*------------------------------------*/

.btn {
  display: inline-flex;
  border-radius: 1rem;
  padding: 0.7rem 1.2rem;
  transition: $transition2;
  border: none;

  &__wrap {
    .btn {
      margin-right: 1.5rem;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  span {
    font-size: 1.3rem;
    font-weight: 700;
    transition: $transition2;
  }

  i,
  img {
    transition: $transition2;

    &:before {
      transition: $transition2;
    }
  }

  &--gray {
    background-color: #eee;
  }

  &--small {
    padding: 0.6rem 1rem;

    i {
    }
  }

  &--dark {
    span,
    i,
    img {
      color: $clr-tertiary;
    }
    i,
    img {
      &:before {
        color: $clr-tertiary;
      }
    }
  }

  &--text {
    padding: 0;
    margin: 0;
    background-color: transparent;
    font-family: "Source Sans Pro", sans-serif;

    span {
      font-weight: 400;
      text-decoration: underline;
    }
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }

  &--icon {
    align-items: center;
    justify-content: center;
    span {
      margin: 0 0.25rem;
      white-space: nowrap;
    }
    i {
      margin-right: 0.7rem;
      font-size: 1.4rem;
    }
  }
}

.button-active {
  background-color: white !important;

  .c-form__buttons__single__check {
    opacity: 1 !important;
  }
}
