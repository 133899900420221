.c-license-checker {
  position: relative;
  display: grid;
  row-gap: 1rem;

  input {
    width: 100%;
    background-color: #fabc00 !important;
    font-weight: 500;
    font-family: "Kenteken", sans-serif !important;
    font-size: 2.2rem !important;
    padding: 0.5rem 2rem 0.5rem 5.5rem !important;
    color: #000;
    border: none !important;
    height: 4.5rem;
    min-height: 4.5rem;
    border-radius: 1rem !important;
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.15);
    text-align: center;
    text-transform: uppercase;

    &::placeholder {
      font-family: "Source Sans Pro", sans-serif !important;
      color: $clr-primary;
    }
  }

  span {
    opacity: 1;
  }

  &-nl {
    position: absolute;
    top: 0;
    left: 0;
    width: 4.5rem;
    height: 4.5rem;
    min-height: 4.5rem;
    background: #1548ec;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    font-weight: bold;

    img {
      width: 2.4rem;
    }

    span {
      font-size: 1.4rem;
      font-family: "Source Sans Pro", sans-serif;
      color: $clr-tertiary;
      text-align: center;
      font-weight: 700;
      line-height: 1;
    }
  }

  &-result {
    display: flex;
    align-items: center;
    padding: 2rem 0 0;

    img {
      width: 2.5rem;
      display: block;
    }

    span {
      color: $clr-primary;
      font-size: 1.7rem;
      margin-left: 1rem;
      font-family: "Source Sans Pro", sans-serif;
    }
  }
}

.c-license-error {
  font-size: 1.2rem;
  color: red;
  margin-top: 0;
  margin-bottom: 2rem;
  display: block;
}
