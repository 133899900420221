.c-icon-button {
  display: flex;
  gap: 1rem;

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }

  &__intro {
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
    .c-icon-button__single {
      width: 100%;
    }
  }

  &__single {
    width: calc(50% - 0.5rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: $gray-primary;
    border-radius: 1rem;
    border: 0;
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.15);
    position: relative;
    padding: 2rem;
    row-gap: 0.8rem;

    &:after {
      content: "";
      position: absolute;
      border: 2px solid transparent;
      width: calc(100% - 4px);
      width: calc(100% - 4px);
      top: 0;
      bottom: 0;
      border-radius: 1rem;
    }

    @media screen and (max-width: 600px) {
      width: calc((100% / 2) - 0.5rem);
      margin-right: 0;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &:last-child {
      margin-right: 0;
    }
    img {
      max-width: 8rem;
      @media screen and (max-width: 600px) {
        max-width: 6rem;
      }
    }
    span {
      font-size: 1.6rem;
      font-weight: 700;
      margin: 0;
      color: #1d2d58;
      text-align: center;
    }
    small {
      font-size: 1.4rem;
      font-weight: 300;
    }
    svg {
      margin: 0 0 0.5rem;
    }
  }

  &--stack {
    flex-direction: column;

    .c-icon-button__single {
      width: 100%;
      align-items: flex-start;
    }
  }

  &--horizontal {
    .c-icon-button__single {
      flex-direction: row;
      justify-content: center;
      column-gap: 1.6rem;
      padding: 1rem !important;

      @media screen and (max-width: 650px) {
        height: 10rem;
        flex-direction: column;
        row-gap: 0.2rem;
        padding: 2rem;
      }

      svg {
        margin: 0;
      }
    }
  }
}
