/*------------------------------------*\
  #COLORS
\*------------------------------------*/

// Colors names
$blue-primary: #1d2d58;
$white-primary: #ffffff;
$orange-primary: #e67a01;
$orange-secondary: #fef8f2;
$gray-primary: #f3f4f6;

// Font colors
$clr-primary: $blue-primary;
$clr-secondary: $orange-primary;
$clr-tertiary: $white-primary;
$clr-quaternary: $gray-primary;

// Box shadow colors

// Element-colors

// $color-selection: $color-main;
// $scrollbar-color: $color-main;

/*------------------------------------*\
  #SHADOWS
\*------------------------------------*/

// Box-shadows

// Text-shadows

/*------------------------------------*\
  #ANIMATION
\*------------------------------------*/

// Transtions
$transition1: 0.28s all;
$transition2: 0.38s all;
$transition3: 0.55s all;

/*------------------------------------*\
  #RESPONSIVE
\*------------------------------------*/

// Device widths
$mobile-width: 520px;
$tablet-width: 768px;
$between-tablet: 900px;
$desktop-width: 1024px;
