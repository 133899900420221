.c-message {
  border-radius: 0.5rem;
  background-color: $clr-tertiary;
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-top: 1rem;

  i {
    font-size: 1.6rem;
    margin-right: 1rem;
  }
  span {
    font-size: 1.6rem;
    line-height: 1.2;
    font-family: "Source Sans Pro", serif;
    font-weight: 800;
    color: $clr-primary;
  }

  &--outline {
    background-color: transparent;
  }
  &--title {
    flex-direction: column;
    padding: 2.5rem 2.5rem 2rem;
    align-items: flex-start;

    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 1.8rem;
      font-weight: 700;
    }
    p {
      font-size: 1.7rem;
      line-height: 1.3;
    }
  }
}
