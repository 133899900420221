$primary-blue-color: #1d2d58;

.s-appointment {
  margin: 2.5rem 0 0 auto;

  @media screen and (max-width: 800px) {
    margin-top: 0.5rem;
  }

  &__alert {
    background-color: rgba(230, 122, 1, 0.05);
    border-radius: 1rem;
    padding: 2rem;

    &__title,
    &__text,
    label {
      font-size: 1.6rem;
      color: $primary-blue-color;
    }

    &--email-check {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      column-gap: 1.2rem;
      row-gap: 1.6rem;

      &__input {
        width: 100%;

        .c-input__group {
          position: relative;
          width: 100%;

          img {
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            width: 2rem;
            height: 2rem;
          }
        }
      }

      .c-form__buttons {
        margin: 0;
        width: auto;
        min-width: fit-content;

        button {
          margin: 0;
          width: 100%;
        }
      }

      @media screen and (max-width: 650px) {
        flex-direction: column;
      }
    }

    .c-form__buttons {
      a {
        i {
          margin-right: 1rem;

          &:before {
            color: $primary-blue-color;
          }
        }
      }
    }
  }

  &__group {
    border: 2px solid #f3f4f6;
    border-radius: 1rem;
    padding: 2rem;

    &.no-padding {
      padding: 0;

      .s-appointment__group__title {
        padding: 2rem 2rem 1rem;
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__grid {
      display: flex;

      &__single {
        margin-right: 1.5rem; //justify-content: space-between;
      }
    }

    &__title {
      font-size: 1.8rem;
      color: $primary-blue-color;

      &--no-margin {
        margin-bottom: 0;
      }

      &--outside {
        padding-left: 2.2rem;
        margin-bottom: 0;
      }
    }

    p {
      color: $primary-blue-color !important;
    }

    small {
      font-size: 1.3rem;
      color: $primary-blue-color;
    }

    .c-form__error {
      color: red !important;
    }

    &--summary {
      padding: 0;

      &__single {
        border-bottom: 2px solid #f3f4f6;
        padding: 2.4rem;

        &:last-of-type {
          border-bottom: 0;
        }
      }
    }
  }

  &--start {
    padding-bottom: 0;
    min-height: calc(100vh - 9rem - 1px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 650px) {
      justify-content: flex-start;
      padding-top: 5rem;
    }
  }

  &--top {
    justify-content: flex-start;

    @media screen and (max-width: 650px) {
      padding-top: 0;
    }
  }

  &__container {
    max-width: 85rem;
    margin: 4rem auto 0;
    display: flex;
    flex-direction: column;
    // border-radius: 2rem;
    justify-content: center;
    background-color: white;
    box-shadow: 0px 3px 8px 2px rgb(0 0 0 / 1%);
    //overflow: hidden;

    &--overview {
      background-color: transparent;
      border: none;
      padding-top: 4rem;
    }

    @media screen and (max-width: 950px) {
      max-width: calc(100% - 8rem);
    }
    @media screen and (max-width: 600px) {
      max-width: calc(100% - 6rem);
    }
    @media screen and (max-width: 450px) {
      max-width: calc(100% - 4rem);
    }
  }

  &__header {
    display: flex;
    background-color: $clr-tertiary;
    width: 100%;

    &__step {
      display: flex;
      align-items: center;
      width: calc(100% / 3);
      padding: 1.5rem;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-right-width: 1px;
      border-right-style: solid;
      background: #eee;

      @media screen and (max-width: 650px) {
        justify-content: center;
      }

      &:last-of-type {
        border-right: none;
      }

      div {
        @include square-dimensions(2rem);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-right: 1rem;

        @media screen and (max-width: 650px) {
          margin-right: 0;
        }

        @media screen and (max-width: 450px) {
          @include square-dimensions(2.5rem);
        }

        span {
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 1.2rem;
          font-weight: 700;
        }
      }

      small {
        font-weight: 700;
        font-size: 1.5rem;

        @media screen and (max-width: 800px) {
          font-size: 1.3rem;
        }
        @media screen and (max-width: 650px) {
          display: none;
        }
      }
    }

    .stepActive {
      border-bottom-color: transparent;
      background: #fff;
    }
  }

  &__overview {
    &__car {
    }

    &__info {
      background-color: $clr-tertiary;
      border-radius: 0.5rem;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;

      &--outline {
        padding: 0;
        border-radius: 0;
        border-bottom: 1px solid darken($clr-quaternary, 20%);
        background-color: transparent;
        padding-bottom: 2rem;
      }

      span {
        font-size: 2rem;
        font-weight: 600;
      }
    }

    &__single {
      margin-top: 0;
      margin-bottom: 1.5rem;

      footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem 2rem;
        margin-top: -3.5rem;

        @media screen and (max-width: 650px) {
          margin-top: 0;
        }

        .s-appointment__footer__step {
          margin-bottom: 0.75rem;
          display: inline-flex;
          width: 100%;
          align-items: center;
          border-radius: 0.5rem;
          --tw-bg-opacity: 1;
          background-color: rgb(251 242 222 / var(--tw-bg-opacity));
          padding: 1.25rem 1.5rem;
          font-size: 1.5rem;
          line-height: 2rem;
          --tw-text-opacity: 1;
          color: rgb(130 92 15 / var(--tw-text-opacity));

          &__icon {
            margin-right: 0.5rem;
            height: 1.5rem;
            width: 1.5rem;
          }
        }
      }

      header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem 2rem;
        border-bottom-width: 1px;
        border-bottom-style: solid;

        .s-appointment__header__step {
          border-bottom: none;
          width: 100%;
          padding: 0;

          @media screen and (max-width: 650px) {
            justify-content: flex-start;

            small {
              display: flex;
              margin-left: 1rem;
            }
          }
        }

        button {
          background-color: #f3f4f6;

          @media screen and (max-width: 650px) {
            i {
              margin-right: 0;
            }
            span {
              display: none;
            }
          }
        }
      }

      &__body {
        padding: 3rem;

        @media screen and (max-width: 650px) {
          padding: 2.5rem;
        }

        &__info {
          display: flex;

          @media screen and (max-width: 650px) {
            flex-direction: column;
          }

          div {
            display: flex;
            flex-direction: column;
            padding-right: 3rem;

            @media screen and (max-width: 650px) {
              width: 100%;
              padding-right: 0;
            }

            span {
              font-size: 1.4rem;
              line-height: 1.4;
            }
          }
        }

        &__row {
          display: flex;

          p {
            margin: 0 0 2rem 0;
          }

          @media screen and (max-width: 650px) {
            flex-direction: column;
          }

          div {
            display: flex;
            flex-direction: column;
            padding-right: 3rem;

            @media screen and (max-width: 650px) {
              padding-right: 0;
              margin-bottom: 1rem;

              &:last-of-type {
                margin-bottom: 0;
              }
            }

            small {
              font-weight: 700;
              font-size: 1.5rem;
            }

            span {
              line-height: 1;
              font-size: 1.5rem;
            }
          }

          &--flex {
            flex-wrap: wrap;

            div {
              width: calc(100% / 3);
              padding-right: 2rem;
              margin-bottom: 1rem;
            }
          }
        }

        &__car {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 2rem;

          @media screen and (max-width: 650px) {
            flex-direction: column;
          }

          .c-license-checker {
            width: auto;
            max-width: 28rem;
            margin-bottom: 0;
            margin-right: 2rem;

            @media screen and (max-width: 650px) {
              max-width: 100%;
              margin-bottom: 1rem;
              margin-right: 0;
            }

            input {
              height: 4.5rem;
              min-height: 4.5rem;

              @media screen and (max-width: 650px) {
                font-size: 2.1rem !important;
              }
            }

            &-nl {
              height: 4.5rem;
              min-height: 4.5rem;

              img {
                max-width: 1.75rem;
              }

              span {
                font-size: 1.4rem;
              }
            }
          }

          .c-message {
            width: 100%;
          }
        }

        &__overview {
          display: flex;

          @media screen and (max-width: 650px) {
            flex-direction: column;
          }

          div {
            margin-bottom: 1rem;

            @media screen and (max-width: 650px) {
              order: 2;
              margin-bottom: 0;
            }

            p {
              line-height: 1.4;
            }

            .c-form {
              margin: 0;

              &__group {
                margin: 0;
              }

              span {
                font-size: 1.4rem;
              }
            }
          }

          aside {
            margin-left: 2.5rem;

            @media screen and (max-width: 650px) {
              order: 1;
              margin-bottom: 1.5rem;
              margin-left: 0;
            }

            div {
              margin-bottom: 0;
              width: 22rem;
              height: 17.4rem;
              border-radius: 1rem;
            }
          }
        }
      }
    }
  }

  &__text {
    margin-bottom: 1rem;

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 1.8rem;
      margin: 0 0 0.5rem;
    }

    small {
      font-size: 1.6rem;
    }

    iframe {
      margin-bottom: 2rem;
    }
  }

  &__button {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    &--half {
      @media screen and (max-width: 450px) {
        flex-direction: column;
      }

      a,
      button {
        width: 50%;

        @media screen and (max-width: 450px) {
          width: 100%;
        }

        &:first-of-type {
          margin-right: 1rem;

          @media screen and (max-width: 450px) {
            margin-right: 0;
            margin-bottom: 1rem;
          }
        }
      }
    }

    button,
    a {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        text-align: center;
        color: white;
      }

      i {
        margin-left: 0.75rem;

        &:before {
          color: white;
        }
      }
    }
  }

  &__1 {
    padding: 4rem;

    @media screen and (max-width: 650px) {
      padding: 3rem;
    }
  }

  &__start {
    width: 102rem;
    margin: -9rem auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 800px) {
      width: calc(100% - 10rem);
    }
    @media screen and (max-width: 600px) {
      margin: 0 auto;
      width: calc(100% - 8rem);
    }
    @media screen and (max-width: 525px) {
      margin: 0 auto;
      width: calc(100% - 6rem);
    }

    .c-icon-button {
      width: 100%;
    }

    &__intro {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      small {
        font-size: 1.4rem;
        font-weight: 300;
        text-align: center;
      }

      h1 {
        font-size: 3rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 4.5rem;

        @media screen and (max-width: 600px) {
          font-size: 3rem;
        }
      }

      ul {
        margin-top: 1rem;
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 1.2rem;
      }
    }
  }

  &__break {
    height: 1rem;
  }

  &__datepicker {
    margin-bottom: 1.5rem;

    &__text {
      padding: 0 2rem 2rem;
    }
  }

  &__time {
    background-color: white;
    width: 100%;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #eaecef;

    h4 {
      margin: 0;
      font-size: 1.8rem;
    }

    h5 {
      font-size: 1.5rem;
      font-family: "Source Sans Pro", serif;
      font-weight: 400;
      margin: 0;
    }

    p {
      font-size: 1.5rem;
      font-family: "Source Sans Pro", serif;
      font-weight: 400;
      margin: 0;
    }

    small {
      font-size: 1.6rem;
      opacity: 0.6;
    }

    &__wrap {
      margin-top: 1rem;
      display: flex;
      flex-wrap: wrap;
    }

    &__single {
      width: calc(100% / 3 - 2rem / 3);

      @media screen and (max-width: 650px) {
        width: 100%;
        margin-right: 0;
      }

      &:nth-of-type(3n + 3) {
        margin: 0 0 1rem 0;
      }

      span {
        font-weight: 700;
        font-size: 1.5rem;
      }
    }

    .isActive {
      span {
        color: $clr-tertiary;
      }
    }
  }

  &__options {
    display: flex;
    flex-direction: column;

    &--half {
      flex-wrap: wrap;
      flex-direction: row;

      .s-appointment__options__wrap {
        width: 100%;
        flex-wrap: wrap;
      }

      .s-appointment__options__single {
        width: calc(50% - 0.5rem) !important;
        margin-right: 0;

        @media screen and (max-width: 650px) {
          width: 100% !important;
        }

        &:nth-of-type(3n + 3) {
          margin-right: 1rem !important;

          @media screen and (max-width: 650px) {
            margin-right: 0 !important;
          }
        }

        &:nth-of-type(odd) {
          margin-right: 1rem;

          @media screen and (max-width: 650px) {
            margin-right: 0;
          }
        }
      }
    }

    &__wrap {
      display: flex;

      @media screen and (max-width: 650px) {
        flex-direction: column;
      }

      .s-appointment__options__single {
        width: calc(100% / 3 - 2rem / 3);

        @media screen and (max-width: 650px) {
          width: 100%;
        }

        &:nth-of-type(3n + 3) {
          margin-right: 0;
        }
      }

      &--multiple {
        flex-wrap: wrap;
      }
    }

    &__single {
      padding: 1.5rem 1rem;
      margin-bottom: 1rem;
      background-color: $clr-tertiary;
      display: flex;
      align-items: center;
      height: auto !important;
      min-height: auto !important;
      justify-content: center;
      flex-direction: column;
      width: 100%;

      &__icon {
        position: relative;
        font-size: 3rem;
        margin-bottom: 0.5rem;
      }

      div {
        width: 8rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 450px) {
          width: 6rem;
        }
      }

      img {
        max-width: 6rem;
        max-height: 3.5rem;
        margin-right: 2rem;

        @media screen and (max-width: 450px) {
          max-width: 4.5rem;
        }
      }

      span {
        font-weight: 700;
        font-size: 1.3rem;
        text-align: center;
        line-height: 1.3;
      }

      &--svg-left {
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 2rem;

        svg {
          margin-right: 1.5rem;
        }
      }
    }

    .isActive {
    }
  }

  &__confirm {
    margin-top: 2rem;

    &__button {
      padding-bottom: 10rem;
    }
  }

  &__image {
    min-height: 28rem;
    height: 28rem;
    margin-bottom: 2rem;
    border-radius: 1rem;
  }
}

.s-confirmed {
  padding: 5rem 0 9rem;
  display: flex;
  position: relative;
  background: rgb(254, 248, 242);
  background: linear-gradient(
    180deg,
    rgba(254, 248, 242, 1) 40%,
    rgba(255, 255, 255, 0) 100%
  );

  @media screen and (max-width: 1800px) {
    padding-bottom: 8.5rem;
  }

  @media screen and (max-width: 1650px) {
    padding-bottom: 8rem;
  }

  @media screen and (max-width: 1500px) {
    padding-bottom: 7.5rem;
  }

  @media screen and (max-width: 1350px) {
    padding-bottom: 7rem;
  }

  @media screen and (max-width: 1100px) {
    padding-bottom: 5.8rem;
  }

  @media screen and (max-width: 1000px) {
    padding-bottom: 5rem;
  }

  @media screen and (max-width: 1000px) {
    padding-bottom: 4.3rem;
  }

  @media screen and (max-width: 800px) {
    background: none;
    background-color: #fef8f2;
    margin: 5rem 1.7rem 0;
    border-radius: 1rem;
    padding: 0;
  }

  .o-container {
    display: flex;
    align-items: center;

    @media screen and (max-width: 800px) {
      flex-direction: column;
      background-color: #fef8f2;
      max-width: 100%;
    }
  }

  &__content {
    padding-right: 15rem;

    @media screen and (max-width: 1022px) {
      padding-right: 5rem;
    }

    @media screen and (max-width: 800px) {
      order: 3;
      padding: 2rem;
    }

    h1,
    h2,
    p,
    li {
      color: $clr-primary;
    }

    h1 {
      font-size: 3.2rem;
      margin-bottom: 1.4rem;
    }

    p {
      margin-bottom: 3rem;
      line-height: 2.2rem;
      max-width: 31rem;
    }

    h2 {
      font-size: 1.8rem;
    }

    ul {
      max-width: 31rem;

      li {
        &:before {
          content: "";
        }
      }
    }
  }

  &__image {
    min-width: 50.8rem;

    @media screen and (max-width: 800px) {
      position: relative;
      order: 1;
      min-width: 100%;
      text-align: center;
      padding-bottom: 2rem;

      @media screen and (max-width: 600px) {
        padding-bottom: 1rem;
      }

      img {
        &:first-of-type {
          max-width: 20.8rem;
          width: 20.8rem;
          margin-top: -5rem;
          margin-left: -4rem;
        }
      }
    }
  }

  &__wave {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    @media screen and (max-width: 800px) {
      order: 2;
    }
  }
}
