.c-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  position: relative;
  background: #fff;
  border: 0 !important;

  &--appointment {
    height: 9rem;
    min-height: 9rem;
  }

  &__logo {
    max-width: 11.6rem;
    max-height: 6.6rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    @media screen and (max-width: 650px) {
      max-width: 10rem;
      max-height: 5.5rem;
    }
  }
  button {
  }

  &__back {
    display: flex;
    align-items: center;
    span {
      font-size: 1.3rem;
      line-height: 1;
    }
    i {
      font-size: 1.302rem;
      margin-right: 1rem;
    }
  }
}
