/*------------------------------------*\
  #Form
\*------------------------------------*/

.c-form {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;

  input {
    width: 100%;
  }

  p.small {
    font-size: 1.3rem;
    line-height: 1.6rem;
    color: #1d2d58;
    margin-top: 1rem;

    a {
      text-decoration: underline;
    }
  }

  &__title {
    @media screen and (max-width: 650px) {
      font-size: 2.5rem;
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
    row-gap: 1rem;

    &--row {
      flex-direction: row;
      flex-wrap: wrap;

      .c-form__buttons__single {
        width: calc((100% / 2) - 0.5rem);
        margin-right: 0;
        margin-bottom: 0;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &__single {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $gray-primary;
      border: 0;
      min-height: 4.5rem;
      padding: 0.5rem 1.5rem;
      text-align: center;
      border-radius: 1rem;
      transition: 0.5s all;
      position: relative;
      box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.15);
      width: calc(50% - 0.5rem);

      &:after {
        content: "";
        position: absolute;
        border: 2px solid transparent;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 1rem;
        left: -2px;
        top: -2px;
      }

      &--full-width {
        width: 100% !important;
      }

      span {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.2;
        color: #1d2d58 !important;

        &.font-color-white {
          color: #ffffff !important;
        }

        @media screen and (max-width: 800px) {
          font-size: 1.6rem;
        }
      }

      &___primary {
        background-color: $orange-primary;
        color: #000000;
        box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.15);
        border: none;
        font-size: 1.6rem;
        width: auto;

        &--full-width {
          width: 100%;
        }

        span {
          color: #000000 !important;
        }

        &:disabled {
          opacity: 0.5;
        }
      }

      &__check {
        opacity: 0;
        position: absolute;
        top: -0.7rem;
        left: -0.7rem;
      }

      @media screen and (max-width: 768px) {
        height: auto;
        padding: 0.5rem 2rem;
      }

      @media screen and (max-width: 650px) {
        width: 100%;
      }

      &--outline {
        background-color: transparent;
      }

      &--small {
        padding: 0.25rem 2.5rem;
        min-height: 4rem;
        height: 4rem;
        border-radius: 2rem;

        span {
          font-size: 1.3rem;
        }
      }
    }

    @media screen and (max-width: 650px) {
      &--start {
        .c-form__buttons__single {
          text-align: left;
        }
      }
    }

    &--half {
      .c-form__buttons__single {
        width: calc(50% - 0.5rem);

        @media screen and (max-width: 650px) {
          width: 100%;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .isActive {
      &:hover {
        span {
          text-decoration: none;
        }
      }

      span {
        color: $clr-tertiary;
        font-weight: 650;
      }
    }

    &--3 {
      button {
        width: calc(100% / 3 - 2rem / 3);

        @media screen and (max-width: 650px) {
          width: 100%;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  &__row {
    margin-bottom: 1.5rem;

    @media screen and (max-width: 650px) {
      margin-bottom: 1.6rem;
    }

    &--horizontal {
      display: flex;
      flex-direction: row;
      column-gap: 2rem;
      margin-bottom: 0;

      @media screen and (max-width: 650px) {
        flex-direction: column;
      }

      &__single {
        margin-bottom: 0.5rem;

        &:first-child {
          min-width: 12rem;
        }

        p {
          margin-bottom: 0;
          line-height: 1.5;
        }
      }
    }

    &--label {
      margin-bottom: 0.2rem !important;
    }

    &--half {
      display: flex;
      gap: 1rem;

      .c-form__group {
        width: 50%;

        &:first-of-type {
          @media screen and (max-width: 650px) {
            margin-right: 0;
            margin-bottom: 1.5rem;
          }
        }

        .c-input__group {
          display: flex;
          align-content: stretch;

          &--prepend {
            background: #eee;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-right: -1px;
            padding: 0 0.5rem;
            border-radius: 1rem 0 0 1rem;
          }

          &--text {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 0.65rem 1rem;
            margin-bottom: 0;
            text-align: center;
            white-space: nowrap;
          }
        }
      }
    }

    &--align-bottom {
      align-items: flex-end;
    }

    &--address {
      display: flex;
      flex-direction: row;

      @media screen and (max-width: 650px) {
        flex-direction: column;
        margin-bottom: 0;
      }

      .c-form__group:nth-of-type(1) {
        width: 40%;

        @media screen and (max-width: 650px) {
          width: 100%;
          margin-bottom: 1.5rem;
        }
      }

      .c-form__group:nth-of-type(2),
      .c-form__group:nth-of-type(3) {
        width: 30%;
        margin-left: 1.5rem;

        @media screen and (max-width: 650px) {
          width: 100%;
          margin-left: 0;
          margin-bottom: 1.5rem;
        }
      }
    }

    &--name {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      flex-wrap: wrap;

      .c-form__group:nth-of-type(1) {
        width: 100%;
      }

      .c-form__group:nth-of-type(1),
      .c-form__group:nth-of-type(3) {
        @media screen and (max-width: 650px) {
          width: 100%;
        }
      }

      .c-form__group:nth-of-type(1),
      .c-form__group:nth-of-type(2) {
        @media screen and (max-width: 650px) {
          padding-right: 0;
        }
      }

      .c-form__group:nth-of-type(2) {
        width: calc(50% - 0.5rem);

        @media screen and (max-width: 650px) {
          width: calc(30% - 0.75rem);
        }
      }

      .c-form__group:nth-of-type(3) {
        width: calc(50% - 0.5rem);
        @media screen and (max-width: 650px) {
          width: calc(70% - 0.75rem);
        }
      }
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    label {
      margin-bottom: 0.5rem;
      font-size: 1.6rem;
      color: #1d2d58;
      font-weight: bold;

      small {
        font-size: 1.3rem;
      }

      button {
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0 0 0 0.5rem;
        text-decoration: underline;
        font-size: 1.4rem;
      }
    }

    input[type="text"],
    .js-input,
    input[type="number"],
    input[type="tel"],
    input[type="date"],
    input[type="email"],
    input[type="password"],
    select {
      height: 4.5rem;
      min-height: 4.5rem;
      background-color: $clr-tertiary;
      padding: 0.5rem 1.5rem;
      border-radius: 0.8rem;
      border-width: 1px;
      border-style: solid;
      border-color: #d1d1d1;
      font-size: 1.5rem;
      font-family: "Source Sans Pro", sans-serif;
      color: #1d2d58;
      box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.15);

      &::placeholder {
        color: darken($clr-quaternary, 25%);
      }
      &:focus {
        box-shadow: none;
      }

      &.confirmed {
        border: 1px solid #afce55;
        box-shadow: inset 0 -2px 0 0 rgba(175, 206, 85, 0.55);

        &:after {
          content: "";
          position: absolute;
          //background-image: url('/images/groen-check.svg');
        }
      }
    }

    input[type="checkbox"] {
      appearance: none;
      -webkit-appearance: none;
      -ms-progress-appearance: none;
      -moz-appearance: none;
      cursor: pointer;
      border: 1px solid #d1d1d1;
      border-bottom-width: 2px;
      height: 14px;
      width: 14px;
      border-radius: 4px;
      min-width: 14px !important;
      display: grid;
      place-content: center;

      &:before {
        content: "";
        width: 9px;
        height: 9px;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em $white-primary;
        transform-origin: bottom left;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      }

      &:checked:before {
        transform: scale(1);
      }

      &:checked {
        background-color: $orange-primary;
        border-color: $orange-primary;
      }
    }

    textarea {
      width: 100%;
      resize: vertical;
    }

    select {
      cursor: pointer;
    }

    &--checkbox {
      label {
        display: flex;
        align-items: flex-start;

        input {
          margin-top: 0.8rem;
        }

        span,
        p {
          font-weight: 400;
          margin-left: 0.75rem;

          a {
            text-decoration: underline;
          }
        }

        p {
          font-size: 1.6rem;
          line-height: 1.2;
          @media screen and (max-width: 650px) {
            font-size: 1.4rem;
            line-height: 1.5;
          }
        }
      }
    }

    &--icon {
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 1.5rem;
        bottom: 0;
        margin: auto;
        width: 1.9rem;
      }

      input {
        padding-left: 5rem !important;
      }
    }
  }

  &__select {
    width: 100%;
    position: relative;

    i {
      position: absolute;
      font-size: 2rem;
      top: 0;
      right: 2rem;
      bottom: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    select {
      width: 100%;
      -webkit-appearance: none;
      appearance: none;
      -moz-appearance: none;
      -ms-progress-appearance: none;
      padding-right: 4rem;
    }

    &__error {
      color: red;
      font-size: 1.4rem;
      height: 4.5rem;
      display: flex;
      align-items: center;

      @media screen and (max-width: 650px) {
        width: 100%;
        align-items: flex-start;
      }
    }
  }

  .c-form__group input[type="checkbox"] {
    margin-top: 0.2rem;
  }
}

.c-input__group--phone-number {
  display: flex;
  flex-direction: row;

  &--prefix {
    border-right: 0 !important;
    border-radius: 0.8rem 0 0 0.8rem !important;
    background: #f3f4f6 !important;
    font-weight: bold;

    .css-1u9des2-indicatorSeparator {
      display: none;
    }

    .css-1xc3v61-indicatorContainer,
    .css-15lsz6c-indicatorContainer {
      padding-left: 0;
      color: #1d2d58;
      cursor: pointer;

      &:hover {
        color: #1d2d58;
      }
    }

    .css-6j8wv5-Input,
    .css-19bb58m {
      padding: 0 !important;
      margin: 0 !important;
    }

    .css-26l3qy-menu {
      margin-top: 0;
    }

    .css-hlgwow {
      padding: 0 8px !important;
    }

    .css-319lph-ValueContainer,
    .css-19bb58m {
      cursor: pointer;

      input {
        caret-color: transparent;
      }
    }

    .css-4ljt47-MenuList > div {
      cursor: pointer;
      padding: 5px 10px;

      img {
        margin-right: 3px !important;
      }
    }

    .css-1dimb5e-singleValue {
      color: #1d2d58;

      img {
        margin-right: 3px !important;
      }
    }

    .css-319lph-ValueContainer {
      padding: 0 1px 0 9px;
    }

    .css-t3ipsp-control,
    .css-13cymwt-control {
      border-radius: 0.8rem 0 0 0.8rem;
      border-width: 1px;
      border-style: solid;
      border-color: #d1d1d1;
      height: 100%;
      box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.15);
      background-color: #f3f4f6;
      width: max-content;
      display: flex;
      flex-direction: row;

      &:hover {
        border-color: #d1d1d1;
      }
    }
  }
  &--suffix {
    border-left: 0 !important;
    border-radius: 0 0.8rem 0.8rem 0 !important;
    height: 5rem !important;
  }
}
