.c-info-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  opacity: 0;
  pointer-events: none;
  color: #272727;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;

  &--active {
    pointer-events: all;
    opacity: 1;

    .c-info-popup__bg {
      opacity: 1;
      pointer-events: all;
    }

    .c-info-popup__close-btn,
    .c-info-popup__content {
      transform: translateX(0);
      opacity: 1;
    }
  }

  > div {
    width: 100% !important;
  }

  &__close-btn {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    @include square-dimensions(1.5rem);
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 6;
    transform: translateX(-100%);
    transition: 0.5s all;
    opacity: 0;
    background-color: transparent;
    padding: 0;

    img {
      width: auto;
      margin: 0 !important;
    }

    img {
      width: 2rem;
    }
  }

  &__bg {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(29, 45, 99, 0.95);
    pointer-events: none;
    opacity: 0;
  }

  &__content {
    background-color: $clr-quaternary;
    display: block !important;
    max-width: 65rem;
    position: relative;
    z-index: 5;
    overflow-x: auto;
    padding: 3.5rem;
    transition: 0.5s all;
    opacity: 0;
    text-align: left !important;
    border-radius: 1rem;

    > div {
      width: 100% !important;
    }

    @media screen and (max-width: 600px) {
      max-width: 90%;
    }

    &__image {
      border-radius: 1rem;
      margin-bottom: 1rem;
      max-width: 100%;
      max-height: 22rem;
    }

    h1,
    p {
      color: $clr-primary;
    }

    h1 {
      font-size: 2.75rem;
    }

    h2 {
      font-size: 2.4rem;
    }

    h3 {
      font-size: 2.2rem;
    }

    h4 {
      font-size: 2rem;
    }

    h5,
    h6 {
      font-size: 1.7rem;
    }

    p,
    ul li,
    ol li {
      font-size: 1.5rem;
    }

    &__footer {
      margin-top: 2rem;
      padding-top: 2rem;
      border-top: 1px solid #c6d8dc;
    }
  }

  &__button {
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    @include square-dimensions(2.2rem);
    background-color: $clr-primary;
    margin: 0 0.5rem;

    small {
      text-align: center;
      //color: $clr-secondary;
      font-size: 1.6rem;
      font-family: serif;
      font-weight: 600;
    }

    &--small {
      @include square-dimensions(1.5rem);

      small {
        font-size: 1.2rem;
      }
    }

    &--large {
      @include square-dimensions(3rem);

      small {
        font-size: 2rem;
      }
    }

    &--red {
      background-color: $clr-tertiary;
    }

    &--outline-dark {
      border: 2px solid $clr-primary;
      background-color: transparent;

      small {
        color: $clr-primary;
      }
    }
  }
}
