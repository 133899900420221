.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  opacity: 0;
  pointer-events: none;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &__content {
    background-color: $clr-tertiary;
    position: relative;
    z-index: 5;
    padding: 3rem;
    width: 100%;
    max-width: 80rem;
    max-height: 90vh;
    border-radius: 0.5rem;
    overflow-x: auto;

    @media screen and (max-width: 800px) {
      width: calc(100% - 10rem);
      max-width: 100%;
    }

    @media screen and (max-width: 650px) {
      width: calc(100% - 8rem);
    }

    @media screen and (max-width: 450px) {
      width: calc(100% - 6rem);
    }

    iframe {
      width: 100%;
      margin-bottom: 2rem;
    }

    h1,
    h2,
    h3,
    h4 {
      margin-bottom: 1rem;
      font-size: 2.5rem;
    }

    small {
      font-size: 1.5rem;
    }

    img {
      margin-bottom: 2rem;
    }
  }

  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: transparent;
    @include square-dimensions(5rem);
    padding: 0;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__buttons {
    display: flex;
    margin-top: 2rem;
    border-top: 1px solid $clr-quaternary;

    @media screen and (max-width: 650px) {
      flex-direction: column;
    }

    a,
    button {
      margin-right: 1rem;

      @media screen and (max-width: 650px) {
        margin-right: 0;
        margin: 0.5rem 0;
        justify-content: center;
      }
    }
  }

  &__mobile-check {
    h5 {
      margin: 0.5rem 0 0 0;
    }

    small {
      font-size: 1rem;
      margin-top: -2rem;
      display: block;
    }

    &__buttons {
      margin-top: 1rem;

      button {
        span {
          color: #fff;
        }

        margin-right: 1rem;
      }
    }
  }
}

.js-modalActive {
  opacity: 1;
  pointer-events: all;
}
