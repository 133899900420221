.c-locations {
  display: flex;
  flex-direction: column;

  &__single {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
    background-color: $clr-tertiary;
    display: flex;
    border: none;
    cursor: pointer;
    border-radius: 1rem;
    overflow: hidden;

    @media screen and (max-width: 650px) {
      padding-left: 0;
    }

    &__media {
      width: 10rem;
      min-width: 10rem;
      max-width: 10rem;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      @media screen and (max-width: 650px) {
        display: none;
      }
    }

    &__info {
      width: 100%;
      display: flex;
      flex-direction: column;
      border: 2px solid #c6c8cd;
      border-radius: 1rem;
      padding: 1.5rem 2rem;

      @media screen and (max-width: 650px) {
        border-left: 2px solid;
        border-bottom-left-radius: 1rem;
        border-top-left-radius: 1rem;
      }

      &__secondary {
        max-width: calc(100% - 10rem);
        margin-top: 0.5rem;

        @media screen and (max-width: 650px) {
          max-width: 100%;
        }

        &__badge {
          font-family: "Source Sans Pro", sans-serif;
          padding: 0.35rem 1rem;
          border-radius: 10rem;
          text-align: center;
          font-size: 1.4rem;
          font-weight: 700;
          color: white;
          margin-right: 0.5rem;
          display: inline-flex;
        }

        small {
          font-size: 1.4rem;
        }
      }

      &__cross {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        height: 1.5rem;
        min-width: 1.5rem;
        min-height: 1.5rem;
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    address {
      font-style: normal;
      text-align: left;
      max-width: calc(100% - 10rem);
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 650px) {
        max-width: 100%;
      }

      h5,
      p {
        margin: 0;
        font-size: 1.6rem;
        color: $blue-primary;
      }

      span {
        font-family: "Source Sans Pro", sans-serif;
        font-size: 1.3rem;
        color: #1d2d58;
      }
    }
    .btn {
      position: absolute;
      top: 0;
      right: 3rem;
      bottom: 0;
      margin: auto;
      height: 3.5rem;
      opacity: 0;
      pointer-events: none;

      @media screen and (max-width: 650px) {
        position: relative;
        right: initial;
        height: auto;
        margin: 1rem 0 0;
        align-items: center;
        justify-content: center;
        display: none;
      }
    }
  }
  .isActive {
    .btn {
      display: flex;
      align-items: center;
      opacity: 1;
      pointer-events: all;

      span {
        text-align: center;
      }
    }
  }
}
