@import "0.defaults/defaults";
@import "1.objects/objects";
@import "2.components/components";
@import "3.utilities/utilities";
@import "4.themes/themes";
@import "5.scope/scope";
@import "6.is-has/is-has";
@import "7.hacks/hacks";

//Colors
$white-primary: #1d2d58;
$blue-primary: #ffffff;
$blue-secondary: #5371a9;
$orange-primary: #ed7b07;
$orange-secondary: #f7b211;
$gray-primary: #ededed;
$gray-secondary: #c6c8cd;
$green-primary: #417505;

//Color list
$clr-primary: $blue-primary;
$clr-secondary: $white-primary;
$clr-tertiary: $gray-primary;
$clr-quaternary: $gray-secondary;
$clr-quinary: $orange-primary;
$clr-senary: $orange-secondary;

//Gradient list
$gradient-primary: linear-gradient(
  to right,
  $orange-primary,
  $orange-secondary
);

// Transtions
$transition1: 0.28s all;
$transition2: 0.38s all;
$transition3: 0.55s all;

// Device widths
$mobile-width: 520px;
$tablet-width: 768px;
$between-tablet: 900px;
$desktop-width: 1024px;

.rdp-root {
  width: 100%;
}

.rdp-months {
  margin: 0;
  width: 100%;
  max-width: 100% !important;
}

.rdp-disabled {
  opacity: 0.2 !important;
}

.rdp-selected .rdp-day_button {
  background: #e67a01 !important;
  border: none !important;
  color: #fff;
}

.rdp-disabled .rdp-day_button {
  background: transparent !important;
}

.rdp-day_button {
  background: #ededed !important;
  width: 2.7rem !important;
  height: 2.7rem !important;
  font-weight: 700 !important;
  margin: 0 auto !important;
  font-size: 1.4rem !important;
}

.rdp-day {
  height: 5rem !important;
  padding: 1.5rem 0.5rem !important;
}

.rdp-month {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
  margin-bottom: 2rem;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8b9898;
  text-align: center;
  font-size: 0.875em;
  width: 14.2857%;
}

.DayPicker-wrap {
  background: $clr-primary;
  padding: 0 3rem;
  margin-top: 2.5rem;

  .appointmentModule-padding {
    width: 100%;
    margin: 0 auto;
    padding: 1rem 0 4rem;
  }
}

.DayPicker {
  background: $clr-primary;
}

.DayPicker,
.DayPicker-wrapper,
.DayPicker-Month {
  width: 100%;
}

.DayPicker-Month {
  margin: 0 !important;
}

.DayPicker-Week {
  position: relative;
}

.DayPicker-Caption {
  color: $clr-secondary;
  font-size: 1.8rem;
  font-family: "Source Sans Pro", sans-serif;
  margin-bottom: 0 !important;
  padding: 0 !important;
  height: 6.6rem;

  div {
    text-align: center;
    height: 6.6rem;
    line-height: 6.6rem;
    font-family: "Roboto Slab", sans-serif;
    font-weight: bold !important;
  }
}

.DayPicker-wrapper {
  width: 100%;
  //max-width: 37.5rem;
  margin: 0 auto;
  position: relative;
}

.DayPicker-Weekdays {
  margin-bottom: 2rem;

  .DayPicker-Weekday {
    abbr {
      margin: 0.75rem 0;
      display: block;
      color: $clr-secondary;
      font-size: 1.6rem;
      font-family: "Roboto Slab", sans-serif;
      font-weight: bold;
    }
  }
}

.DayPicker-Day {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  padding: 1.5rem 0.5rem;
  color: $clr-secondary;
  z-index: 2;
}

.DayPicker-NavBar {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6.6rem;
  border-top: 2px solid #f3f4f6;
  border-bottom: 2px solid #f3f4f6;

  button {
    background: transparent;
    color: #ed7b07;
    font-size: 2rem;
    border: none;
    padding: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3rem;
    height: 3rem;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;

    &.button-prev {
      left: 2rem;
    }

    &.button-next {
      right: 2rem;
    }
  }
}

.DayPicker-Body {
  padding-top: 1.5rem;
}

.DayPicker-Day {
  position: relative;
  transition: $transition1;
  height: 5rem;
  font-size: 1.6rem;

  &:before {
    position: absolute;
    top: calc(50% - 1.6rem);
    left: calc(50% - 1.65rem);
    content: "";
    width: 3.2rem;
    height: 3.2rem;
    background: $gray-primary;
    border-radius: 10rem;
    transition: $transition1;
    z-index: -1;
  }
}

.DayPicker-Day--disabled {
  color: black !important;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.2;

  &:before {
    display: none;
  }
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--selected
  ):not(.DayPicker-Day--outside):hover {
  background-color: transparent;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ) {
  position: relative;
  background-color: transparent !important;
  z-index: 1;
  color: $clr-primary;

  &:before {
    background: $clr-quinary;
    z-index: -1;
  }
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ):hover {
  background: transparent;
}

.DayPicker-Day--outside {
  opacity: 0;
}

.DayPicker-Day--today {
  color: $orange-primary !important;

  &:before {
    display: block;
  }
}

.s-appointment__datepicker {
  height: auto;
  border-radius: 1.5rem;
  overflow: hidden;
}

.s-appointment__datepicker {
  height: auto !important;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--selected
  ):not(.DayPicker-Day--outside):hover {
  background: #eee !important;
  border-radius: 0;
}

.btn:disabled {
  opacity: 0.3;
}

html {
  scroll-behavior: smooth;
}

.u-fade,
.c-form__row,
.c-form__group,
.c-form__buttons {
  transition: all ease-in-out 0.3s;
}

.fade-enter {
  opacity: 0;

  &-done {
    opacity: 1;
  }
}

.c-form__group--icon img {
  top: 0 !important;
}

.c-error {
  position: fixed;
  bottom: 3rem;
  left: 3rem;
  padding: 1rem 2rem;
  border-top: 3px solid #f44336;
  background: #fff;
}

.s-appointment {
  padding-bottom: 5rem;
}

a {
  color: inherit;

  &:hover {
    cursor: pointer;
  }
}

.tst-1 {
  color: #1d2d58;
}

.s-appointment__overview__single__body__row {
  flex-wrap: wrap;
}

.btn--info {
  border: none;
  margin: 0;
  background: none;
  display: inline-flex;
  align-self: flex-start;
  padding: 0;
  font-size: 1.3rem;
}

.c-form__buttons,
.s-appointment__options__wrap {
  .btn--info {
    padding: 0 !important;
    margin-left: 0.4rem !important;
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
  }
}

.s-appointment__options__single {
  .btn--info {
    padding: 0;

    position: absolute;
    right: 1.2rem;
    width: 0;
    top: 0.4rem;
  }
}

.c-info-popup__close span {
  color: #272727 !important;
}

.s-appointment__overview__single header .s-appointment__header__step {
  background: #fff;
}

.c-info-popup__content p {
  font-weight: normal !important;
}

.c-switch {
  padding: 6rem 0;

  @media screen and (max-width: 768px) {
    padding: 4rem 0;
  }
  @media screen and (max-width: 650px) {
    padding: 3rem 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $clr-tertiary;
    border-bottom: 1px solid #f3f4f6;
    padding: 1.2rem 3rem;
    position: relative;
    height: 9rem;

    &__help {
      display: flex;
      align-items: center;

      .icomoon {
        margin-right: 0.5rem;
      }
    }

    @media screen and (max-width: 650px) {
      height: 7rem;
      padding: 1.2rem 2rem;
    }

    &__back {
      display: flex;
      align-items: center;

      span {
        font-family: "Roboto Slab", serif;
        margin-left: 0.5rem;
        font-size: 1.2rem;
      }

      i {
        transform: rotate(180deg);
        font-size: 1rem;
      }
    }

    img {
      width: 11rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;

      @media screen and (max-width: 650px) {
        width: 8rem;
      }
    }

    &__help {
      small {
        font-family: "Roboto Slab", serif;
        margin-right: 0.5rem;
        font-size: 1.2rem;
        @media screen and (max-width: 650px) {
          display: none;
        }
      }

      .btn {
        padding: 0.75rem 0.75rem;

        @media screen and (max-width: 450px) {
          padding: 0.3rem;
        }
        @media screen and (max-width: 350px) {
          display: none;
        }

        i {
          @media screen and (max-width: 450px) {
            font-size: 1.3rem;
          }
        }

        span {
          font-size: 1.5rem;

          @media screen and (max-width: 450px) {
            font-size: 1.3rem;
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 650px) {
      flex-direction: column;
    }
  }

  &__option {
    width: calc(50% - 2.5rem);
    position: relative;
    border-radius: 1.5rem;
    border: 3px solid #f3f4f6;
    padding-top: 10rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    overflow: hidden;

    @media screen and (max-width: 1023px) {
      width: calc(50% - 1rem);
      padding-top: 8rem;
    }

    @media screen and (max-width: 650px) {
      width: 100%;
      margin-bottom: 1rem;
      padding-top: 3rem;
    }

    &__icon {
      position: absolute;
      top: 2rem;
      right: 2rem;
      max-width: 6rem;
    }

    &__image {
      filter: grayscale(1);
      transition: $transition2;

      @media screen and (max-width: 768px) {
        filter: none;
      }
      @media screen and (max-width: 650px) {
        max-width: 40rem;
      }
      @media screen and (max-width: 450px) {
        max-width: 90%;
      }
    }

    &__text {
      padding: 1rem 4.5rem 3rem 4.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @media screen and (max-width: 1150px) {
        padding: 1rem 3rem 3rem;
      }

      @media screen and (max-width: 1023px) {
        padding: 1rem 2rem 2rem;
      }

      div {
        margin: 0 2rem 0 0;

        @media screen and (max-width: 1023px) {
          margin: 0 1rem 0 0;
        }

        h2 {
          margin: 0 0 0.25rem;
          line-height: 1;
          font-size: 3rem;

          @media screen and (max-width: 1023px) {
            font-size: 2rem;
          }
        }

        small {
          font-size: 1.7rem;
          font-family: "Roboto Slab", serif;
          font-weight: 300;
          line-height: 1.1;
          display: flex;

          @media screen and (max-width: 1023px) {
            font-size: 1.5rem;
          }
        }
      }

      .btn {
        white-space: nowrap;
        margin: 0;

        @media screen and (max-width: 1023px) {
          padding: 0.75rem;
          span {
            font-size: 1.5rem;
          }
          i {
            font-size: 1.5rem;
          }
        }
      }
    }

    &:hover {
      border: 3px solid $clr-secondary;

      .c-switch__option__image {
        filter: none;
      }

      .btn--gray {
        border: 2px solid $clr-secondary;
        background-color: $clr-secondary;
        border-bottom: 3px solid darken($clr-secondary, 4%);

        span {
          color: $clr-tertiary;
        }

        i {
          &:before {
            color: $clr-tertiary;
          }
        }
      }
    }
  }
}

#root {
  min-height: 100vh;
}

.react-select {
  font-size: 1.5rem;

  input {
    min-height: 0 !important;
    height: auto !important;
  }
}

.s-appointment__overview__single__body {
  .c-form__group--checkbox label {
    align-items: flex-start;
  }
}

.s-appointment__start__intro {
  p,
  ul,
  li {
    font-size: 1.2rem;
  }

  ul {
    margin-bottom: 0;
  }
}

ul {
  font-size: 1.5rem;
}

.c-form__group input[type="checkbox"] {
  min-width: 2rem;
}

.c-form__group--checkbox label span {
  flex: 1;
}
