.o-container {
  width: 100%;
  margin: 0 auto;

  &--primary {
    max-width: 65.8rem;

    @media screen and (max-width: 1024px) {
      max-width: calc(100% - 10rem);
    }
    @media screen and (max-width: 750px) {
      max-width: calc(100% - 8rem);
    }
    @media screen and (max-width: 500px) {
      max-width: calc(100% - 3rem);
    }
    @media screen and (max-width: 350px) {
      max-width: calc(100% - 2rem);
    }
  }

  &--secondary {
    max-width: 110rem;

    @media (max-width: 1375px) {
      max-width: calc(100% - 10rem);
    }
    @media (max-width: 750px) {
      max-width: calc(100% - 8rem);
    }
    @media (max-width: 500px) {
      max-width: calc(100% - 5rem);
    }
    @media (max-width: 350px) {
      max-width: calc(100% - 4rem);
    }
  }
}
